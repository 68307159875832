<template>
  <div class="finance-list-item">
    <div class="finance-list-item_media">
      <div class="icon icon-check finance-list-item_icon-received" v-if="received" />
      <svg class="finance-list-item_icon-expected" v-else width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.25">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M14.6668 7.99967C14.6668 4.31762 11.6812 1.33301 8.00016 1.33301C4.31811 1.33301 1.3335 4.31762 1.3335 7.99967C1.3335 11.6817 4.31811 14.6663 8.00016 14.6663C11.6812 14.6663 14.6668 11.6817 14.6668 7.99967ZM7.55925 4.69702C7.92743 4.69702 8.22591 4.9955 8.22591 5.36369V7.91964L10.733 8.75684C11.0818 8.8749 11.2688 9.25333 11.1507 9.60207C11.0326 9.95082 10.6542 10.1378 10.3055 10.0198L7.34548 9.01772C7.07476 8.92607 6.89258 8.67206 6.89258 8.38625V5.36369C6.89258 4.9955 7.19106 4.69702 7.55925 4.69702Z" fill="#111111"/>
        </g>
      </svg>
    </div>
    <div class="finance-list-item_inner">
      <div class="finance-list-item_subtitle">
        <template v-if="received">
          <span
            v-if="Number(item.datetime_fee_receive)"
          >
            {{ $moment(Number(`${item.datetime_fee_receive}000`)).locale('ru').format('DD MMMM') }}
          </span>
        </template>
        <span v-else>Ожидается оплата</span>
      </div>
      <div class="finance-list-item_title">
        <span>Гонорар: {{ item.title }}</span>
      </div>
    </div>
    <div class="finance-list-item_after">
      <div class="received-value" v-if="received">+ {{sumSeparator(item.fee)}}</div>
      <div class="expected-block" v-else>
        <span class="expected-block_value">{{ sumSeparator(item.fee) }}</span>
        <div
          class="expected-block_button transparent-button"
          @click="receiptButtonClick(item)"
        >
          Отмет. как полученный
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinanceListItem',
  props: {
    received: Boolean,
    item: Object,
  },
  emits: ['onload'],
  methods: {
    receiptButtonClick(item) {
      this.$eventBus.emit('close-vacancy-modal-open', { vacancyId: item.id },);
    },
  },
};
</script>

<style scoped lang="scss">
.finance-list-item {
  padding: 24px 0;
  display: flex;
  &_media {
    margin-right: 9px;
    flex-shrink: 0;
  }
  &_inner {
    width: 100%;
    & > * {
      margin-bottom: 9px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &_after {
    flex-shrink: 0;
    align-self: center;
  }
  &_title {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
  }
  &_subtitle {
    font-size: 13px;
    line-height: 17px;
    color: rgba(var(--page-color-main-rgb), .5);
  }
  .received-value {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #67C7AC;
  }
  .expected-block {
    display: flex;
    flex-direction: column;
    text-align: right;
    & > * {
      margin-bottom: 5px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &_value {
      font-weight: 600;
      font-size: 13px;
      line-height: 17px;
      color: rgba(var(--page-color-main-rgb), .4);
    }
    &_button {
      display: inline-block;
      text-align: right;
    }
  }
}
</style>
