<template>
  <div class="page half-width finance-page">
    <div class="page-content">
      <div class="page-header">
        <div class="page-title">Финансы</div>
        <div class="input-finance-period">
          <select name="period" dir="rtl" v-model="currentIndex">
            <option
              v-for="(date, index) in dates"
              :key="index"
              :value="index"
            >
              {{
                $moment(date)
                  .locale('ru')
                  .format('MMMM YYYY')
              }}
            </option>
          </select>
          <div class="icon icon-arrow rotate-left"></div>
        </div>
      </div>
      <FinanceHead :value="currentInfo.earned"/>
      <v-tabs v-model="selectedTab">
        <v-tab :ripple="false">Получено: {{ sumSeparator(currentInfo.earned || 0) }}</v-tab>
        <v-tab :ripple="false">Ожидается: {{ sumSeparator(currentInfo.expected || 0) }}</v-tab>
      </v-tabs>
      <Loader height="120" v-if="loading"/>
      <v-tabs-items v-else v-model="selectedTab">
        <v-tab-item>
          <div class="finance-list" v-if="currentInfo.earned_vacancy && currentInfo.earned_vacancy.length > 0">
            <ul>
              <li v-for="vacancy in currentInfo.earned_vacancy" :key="vacancy.id">
                <FinanceListItem :received="true" :item="vacancy"/>
              </li>
            </ul>
          </div>
          <NoResults v-else>
            <template #text>Нет заработанных денег в выбранном месяце</template>
          </NoResults>
        </v-tab-item>
        <v-tab-item>
          <div class="finance-list" v-if="currentInfo.expected_vacancy && currentInfo.expected_vacancy.length">
            <ul>
              <li v-for="vacancy in currentInfo.expected_vacancy" :key="vacancy.id">
                <FinanceListItem :received="false" :item="vacancy" @onload="load"/>
              </li>
            </ul>
          </div>
          <NoResults v-else>
            <template #text>Нет ожидаемых денег в выбранном месяце</template>
          </NoResults>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="page-right-content">
<!--      <InviteBlock/>-->
    </div>
  </div>
</template>

<script>
import InviteBlock from '@/views/profile/InviteBlock.vue';
import FinanceListItem from '@/views/finance/FinanceListItem.vue';
import FinanceHead from '@/views/finance/FinanceHead.vue';

export default {
  name: 'Finance',
  components: {
    FinanceHead,
    FinanceListItem,
    InviteBlock,
  },
  data() {
    return {
      currentIndex: 0,
      dateAtInit: new Date(),
      finances: [],
      selectedTab: 0,
      loading: false,
    };
  },
  methods: {
    load() {
      const date = this.$moment(this.dateAtInit)
        .format('YYYY-MM');
      this.loading = true;
      return this.$server.request2('user/finances', { date }, (data)=>{
        this.loading = false;
        this.finances = data.response;
      }, ()=>{
        this.loading = false;
      })
    },
  },
  computed: {
    dates() {
      const arr = new Array(24).fill(null);
      return arr.map((_, index) => this.$moment(this.dateAtInit)
        .date(1)
        .add(-index, 'months')
        .format('YYYY-MM-DD'));
    },
    currentInfo() {
      return this.finances[this.currentIndex] || {};
    },
  },
  mounted() {
    this.load();
  },
  created() {
    this.$eventBus.on('vacancy-close', ()=>{
      this.load();
      this.selectedTab = 1;
    });
  },
};
</script>

<style scoped lang="scss">
.finance-page {
  padding-right: 500px;
}

.page-header {
  margin-bottom: 28px;
}

.tabs {
  &::v-deep(.tab-link) {
    width: 100%;
  }
}

.input-finance-period {
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  position: relative;

  select {
    position: relative;
    z-index: 1;
    padding-right: 18px;
    background: transparent;
    text-transform: capitalize;
  }

  .icon {
    top: 50%;
    right: 0;
    transform: translate(0, -50%) rotate(-90deg);
    position: absolute;
    font-size: 12px;
    margin-left: 8px;
    color: rgba(var(--page-color-main-rgb), .25);
  }
}

.finance-list {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: none;
    }
  }
}

@media screen and (max-width: 1160px) {
  .finance-page {
    padding-right: 300px;
  }
}
</style>
